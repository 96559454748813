import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import "@/assets/css/app.min.css";
import "@/assets/css/custom.css";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import { createPinia } from "pinia";
import moment from "moment";
import { VueQueryPlugin } from "@tanstack/vue-query";
import "sweetalert2/dist/sweetalert2.min.css";
// import VueSidebarMenu from "vue-sidebar-menu";

createApp(App).config.productionTip = false;
axios.interceptors.request.use(
  (request) => {
    //  request.headers.ContentType='application/json';
    request.headers.Accept = "application/json";
    if (request.url.includes("api")) {
      request.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(router.currentRoute.value.fullPath);
    if (error.response.data.code == 401 || error.response.status == 401) {
      if (router.currentRoute.value.fullPath != "/login") {
        localStorage.setItem(
          "previousRoute",
          router.currentRoute.value.fullPath
        );
      }
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      router.push({ path: "/login" });
    }
    return error.response.data;
  }
);
createApp(App)
  .use({
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
  })
  .use(createPinia())
  .use(VueAxios, axios, Vuelidate)
  .use(router)
  .use(VueQueryPlugin)
  .use(Vuelidate)
  .use(VueSweetalert2)
  .use()
  .directive("access", {
    // bind: function (el, binding, vnode, prevVnode) {
    created(el, binding, vnode, prevVnode) {
      // see below for details on arguments
      let user = JSON.parse(localStorage.getItem("profile"));
      if (user == null) {
        if (router.currentRoute.value.fullPath == "/login") {
          router.push("/login");
        }
      }
      // let hasPermission = hasPermission();
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called right before the element is inserted into the DOM.
    beforeMount(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called before the parent component is updated
    beforeUpdate(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called after the parent component and
    // all of its children have updated
    updated(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called before the parent component is unmounted
    beforeUnmount(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);
      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // called when the parent component is unmounted
    unmounted(el, binding, vnode, prevVnode) {
      let user = JSON.parse(localStorage.getItem("profile"));
      console.log(user);

      // if (
      //   binding.value.some((e) => {
      //     return (
      //       e ==
      //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
      //     );
      //   })
      // ) {
      //   vnode.el.style.display = "block";
      // } else {
      //   vnode.el.style.display = "none";
      // }
    },
    // if (
    //   binding.expression.some((e) => {
    //     return (
    //       e ==
    //       JSON.parse(localStorage.getItem("profile")).User.UserRole.Role.name
    //     );
    //   })
    // ) {
    //   vnode.elm.style.display = "block";
    // } else {
    //   vnode.elm.style.display = "none";
    // }
    // },
  })
  .mount("#app");
